import classes from "../Pages.module.css";
import { Row, Col, Tabs, Skeleton, message } from "antd";
import axios from "axios";
import DataContext from "../../../Context/dataContext";
import { useState, useEffect, useContext, useRef } from "react";
import BasicDetail from "./BasicDetail";
import SizeDetail from "./SizeDetail";
import TaxDetail from "./TaxDetail";
import dayjs from "dayjs";
import SyncLoader from "react-spinners/SyncLoader";
import { useNavigate } from "react-router-dom";
import DummyTable from "./DummyTable"; // Import the dummy

const { TabPane } = Tabs;

const identifiers = ["bookingSize"];

const RequisitionNew = (props) => {
  // console.log(props, "props in size detail");
  const employeeData = useContext(DataContext);
  const history = useNavigate();

  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(true);
  const [editDode, setEditDode] = useState(false);
  const sizeRef = useRef(null);
  const [allData, setAllData] = useState({
    salesOrder: [
      {
        distributor_name: null,
        distributor_code: null,
        dealer_name: null,
        order_type: null,
        booking_date: dayjs(new Date()).format("DD-MM-YYYY"),
        invoice_type_code: null,
        payment_days: null,
        del_site_code: null,
        auth_status: null,
        freight_type_code: null,
        customer_po_no: null,
        broker_code: null,
        payment_code: null,
        tolerance: null,
        payment_amt: null,
        remarks: null,
        type: "w",
        sauda_code: null,
      },
    ],

    bookingSize: [
      {
        item_code: null,
        size_code: null,
        quality: null,
        uom: null,
        no_pcs: null,
        qty: null,
        book_rate_guage: null,
        discount_on: null,
        discount_amount: null,
        bk_rate: null,
        booking_rate: null,
        dis_type: null,
        tot_item_amt: null,
        net_rate: null,
        net_size_rate: null,
      },
    ],
  });

  const [ad, setAD] = useState(null);
  const [isItemDetailVisible, setIsItemDetailVisible] = useState(true);

  const handleReqCodeChange = (newReqCode) => {
    if (newReqCode) {
      setIsItemDetailVisible(false);
    } else {
      setIsItemDetailVisible(true);
    }
  };

  useEffect(() => {
    setEditMode(true);
    setAD(null);
    setLoading(false);
    axios
      .get(employeeData.URL + "/api/v1/salesOrder/additional-data", {
        withCredentials: true,
      })
      .then((response) => {
        // console.log(response);
        setAD((ad) => {
          let newad = response.data.data;
          return {
            ...newad,
            TYPE: {
              fields: [{ name: "key" }, { name: "value" }],
              rows: [
                { key: "wr", value: "With Contract" },
                { key: "w", value: "Without Contract" },
              ],
            },
            Dis_On: {
              fields: [{ name: "key" }, { name: "value" }],
              rows: [
                { key: "r", value: "Rate" },
                { key: "ta", value: "Total Amt" },
              ],
            },
            Dis_Type: {
              fields: [{ name: "key" }, { name: "value" }],
              rows: [
                { key: "p", value: "%" },
                { key: "a", value: "A" },
              ],
            },
          };
        });
      })
      .catch((error) => {
        // console.log(error);
      });
  }, []);

  //////////////////////////////////
  const calculateFinalRate = (rate, disOn, discount, disType) => {
    let finalRate = rate;

    if (disOn === "r") {
      // Discount on Rate
      if (disType === "p") {
        finalRate = rate * (1 - discount / 100); // Apply percentage discount to rate
      } else if (disType === "a") {
        finalRate = rate - discount; // Apply fixed amount discount to rate
      }
    } else if (disOn === "ta") {
      // console.log(true, "mmm");
      if (disType === "p") {
        // console.log(disType);
        // console.log(disType);
        finalRate = rate;
        // netAmount = qty * rate - (qty * rate * discount) / 100;
      } else if (disType === "a") {
        // netAmount = qty * rate - discount;
        finalRate = rate;
      }
    }

    return finalRate > 0 ? finalRate : 0; // Ensure final rate is non-negative
  };

  const calculateNetAmount = (amount, discount, disType, disOn, qty, frate) => {
    // console.log(disType);
    // console.log(disType);
    let netAmount = amount;

    if (disOn === "r") {
      if (disType === "p") {
        netAmount = amount * (1 - discount / 100); // Apply percentage discount to amount
      } else if (disType === "a") {
        netAmount = frate * qty; // Apply fixed amount discount to amount
      }
    } else if (disOn === "ta") {
      // console.log(true, "mmm");
      if (disType === "p") {
        // console.log(disType);
        // console.log(disType);
        // finalRate = rate;
        netAmount = qty * frate - (qty * frate * discount) / 100;
      } else if (disType === "a") {
        netAmount = qty * frate - discount;
        // finalRate = rate;
      }
    }

    return netAmount > 0 ? netAmount : 0; // Ensure net amount is non-negative
  };

  const handleInputChange = (value, uniqueCode) => {
    const newPurreqDetail = allData.bookingSize.map((item) => {
      if (item.unique_code === uniqueCode) {
        const amount = (value || 0) * item.sauda_qty;
        const finalRate = calculateFinalRate(
          value,
          item.disOn,
          item.discount,
          item.distype
        );
        const netAmount = calculateNetAmount(
          amount,
          item.discount,
          item.distype,
          item.disOn,
          item.sauda_qty,
          finalRate
        );

        return {
          ...item,
          rate: value,
          amount: amount,
          finalRate: finalRate,
          netAmount: netAmount,
        };
      }
      return item;
    });

    setAllData((prevData) => ({
      ...prevData,
      bookingSize: newPurreqDetail,
    }));
  };

  const handleSelectChange = (value, uniqueCode, field) => {
    const newPurreqDetail = allData.bookingSize.map((item) => {
      if (item.unique_code === uniqueCode) {
        const updatedItem = {
          ...item,
          [field]: value,
        };
        // console.log(updatedItem, "upppppppppppppppppppppppppp");
        // Recalculate finalRate and netAmount after select change
        const finalRate = calculateFinalRate(
          updatedItem.sauda_rate,
          updatedItem.disOn,
          updatedItem.discount,
          updatedItem.distype
        );
        const netAmount = calculateNetAmount(
          updatedItem.amount,
          updatedItem.discount,
          updatedItem.distype,
          updatedItem.disOn,
          updatedItem.sauda_qty,
          finalRate
        );

        return {
          ...updatedItem,
          finalRate: finalRate,
          netAmount: netAmount,
        };
      }
      return item;
    });

    setAllData((prevData) => ({
      ...prevData,
      bookingSize: newPurreqDetail,
    }));
  };

  const handleDiscountChange = (value, uniqueCode) => {
    const newPurreqDetail = allData.bookingSize.map((item) => {
      if (item.unique_code === uniqueCode) {
        const amount = item.sauda_rate * item.sauda_qty;
        const finalRate = calculateFinalRate(
          item.sauda_rate,
          item.disOn,
          value,
          item.distype
        );
        const netAmount = calculateNetAmount(
          amount,
          value,
          item.distype,
          item.disOn,
          item.sauda_qty,
          finalRate
        );

        return {
          ...item,
          discount: value,
          amount: amount,
          finalRate: finalRate,
          netAmount: netAmount,
        };
      }
      return item;
    });

    setAllData((prevData) => ({
      ...prevData,
      bookingSize: newPurreqDetail,
    }));
  };

  const handleSizeChange = (value, uniqueCode) => {
    const updatedData = allData.bookingSize.map((item) => {
      if (item.unique_code === uniqueCode) {
        return {
          ...item,
          size_code: value, // Only update the size_code field
        };
      }
      return item;
    });

    // Update the state with the new size data
    setAllData((prevData) => ({
      ...prevData,
      bookingSize: updatedData,
    }));
  };
  const handleSave = (event) => {
    var formv = true;

    identifiers.forEach((val) => {
      let count = 0;
      allData[val].forEach((data) => {
        if (!data) {
          formv = formv && false;
          if (count === 0) {
            message.error({
              content: "Empty Fields In " + val.toUpperCase() + " Tab!!!",
              className: "custom-class",
              style: {
                marginTop: "1vh",
              },
            });
          }

          count = count + 1;
        }
      });
    });

    if (formv) {
      setLoading(true);
      const postData = {
        ...allData,
        bookingSize: allData.bookingSize.map((dep) => {
          return {
            ...dep,
          };
        }),
      };

      console.log(postData);
      // Add validation logic here
      // if (

      //   allData.salesOrder[0].booking_date == null ||
      //   allData.salesOrder[0].dealer_name == null ||
      //   allData.salesOrder[0].distributor_code == null ||
      //   allData.salesOrder[0].del_site_code == null ||
      //   allData.salesOrder[0].freight_type_code == null ||
      //   allData.salesOrder[0].customer_po_no == null ||
      //   allData.salesOrder[0].type == null
      // ) {
      //   message.error({
      //     content: "Please Fill The Mandatory Fields!!!",
      //     className: "custom-class",
      //     style: {
      //       marginTop: "1vh",
      //     },
      //   });
      //   setLoading(false);
      //   return; // Stop further execution if validation fails
      // }
      const missingFields = [];

      // Check for missing or empty fields in allData.salesOrder
      if (
        allData.salesOrder[0].booking_date == null ||
        allData.salesOrder[0].booking_date === ""
      ) {
        missingFields.push("Booking Date");
      }
      if (
        allData.salesOrder[0].dealer_name == null ||
        allData.salesOrder[0].dealer_name === ""
      ) {
        missingFields.push("Dealer Name");
      }
      if (
        allData.salesOrder[0].distributor_code == null ||
        allData.salesOrder[0].distributor_code === ""
      ) {
        missingFields.push("Customer name");
      }
      if (
        allData.salesOrder[0].del_site_code == null ||
        allData.salesOrder[0].del_site_code === ""
      ) {
        missingFields.push("Delivery Site ");
      }
      if (
        allData.salesOrder[0].freight_type_code == null ||
        allData.salesOrder[0].freight_type_code === ""
      ) {
        missingFields.push("Freight Type ");
      }
      if (
        allData.salesOrder[0].customer_po_no == null ||
        allData.salesOrder[0].customer_po_no === ""
      ) {
        missingFields.push("Customer PO No");
      }
      if (
        allData.salesOrder[0].type == null ||
        allData.salesOrder[0].type === ""
      ) {
        missingFields.push("Type");
      }

      // If there are any missing fields, show an error message and stop further execution
      if (missingFields.length > 0) {
        message.error({
          content: `Please Fill The Mandatory Fields: ${missingFields.join(
            ", "
          )}`,
          className: "custom-class",
          style: {
            marginTop: "1vh",
          },
        });
        setLoading(false);
        return; // Stop further execution if validation fails
      }

      // Check if sales order type is 'wr'
      if (allData.salesOrder[0].type === "wr") {
        // Only check the size_code field for each row
        const missingSizeCodeRows = allData.bookingSize.filter(
          (detail, index) => !detail.size_code
        );

        if (missingSizeCodeRows.length > 0) {
          missingSizeCodeRows.forEach((_, index) => {
            const errorMessage = `Row ${
              index + 1
            }: Please fill the Size field.`;
            message.error({
              content: errorMessage,
              className: "custom-class",
              style: {
                marginTop: "1vh",
              },
            });
          });
          setLoading(false);
          return; // Stop further execution if validation fails
        }
      } else {
        // Perform the regular validation checks
        const allFieldsNull = allData.bookingSize.every(
          (detail) =>
            detail.item_code === null &&
            detail.uom_code === null &&
            detail.size_code === null &&
            detail.quality_code === null &&
            detail.total_qty === null &&
            detail.hsn === null &&
            detail.remarks === null &&
            detail.rate === null &&
            detail.amount === null &&
            detail.rate1 === null &&
            detail.amount2 === null &&
            detail.discount_on === null &&
            detail.discount === null &&
            detail.dis_type === null &&
            detail.qty === null
        );

        if (allFieldsNull) {
          message.error({
            content: "Please Fill At Least One Row In The Item Detail Tab!!!",
            className: "custom-class",
            style: {
              marginTop: "1vh",
            },
          });
          setLoading(false);
          return; // Stop further execution if all fields are null
        }

        // Mandatory field checks for other types
        const fieldDisplayNameMap = {
          item_code: "Item",
          uom_code: "Uom",
          size_code: "Size",
          quality_code: "Grade",
          total_qty: "Quantity",
          hsn: "HSN",
          remarks: "Remarks",
          rate: "Basic Rate",
          amount: "Amount",
          rate1: "Rate1",
          amount2: "Gross Amount",
          discount_on: "Discount On",
          discount: "Discount Value",
          dis_type: "Discount Type",
        };

        const invalidRows = allData.bookingSize.filter((detail, index) => {
          const mandatoryFields = [
            "item_code",
            "uom_code",
            "size_code",
            "quality_code",
            "hsn",
            "total_qty",
            "rate",
            "amount",
            "rate1",
            "amount2",
          ];
          const missingFields = mandatoryFields.filter(
            (field) =>
              detail[field] === null ||
              detail[field] === undefined ||
              detail[field] === ""
          );
          if (missingFields.length > 0) {
            const displayNames = missingFields.map(
              (field) => fieldDisplayNameMap[field]
            );
            const errorMessage = `Row ${
              index + 1
            }: Please Fill All Mandatory Fields (${displayNames.join(", ")})`;

            message.error({
              content: errorMessage,
              className: "custom-class",
              style: {
                marginTop: "1vh",
              },
            });

            setLoading(false);
            return true;
          }
          return false;
        });

        if (invalidRows.length > 0) {
          return; // Stop further execution if validation fails
        }
      }

      // Move this line outside of the filter function

      axios
        .post(employeeData.URL + "/api/v1/salesOrder/create-sales", postData, {
          withCredentials: true,
          credentials: "include",
        })

        .then((response) => {
          if (response.data.status === "fail") {
            message.error({
              content: response.data.message,
              className: "custom-class",
              style: {
                marginTop: "2vh",
              },
            });

            setLoading(false);
          } else {
            message.success({
              content: "Order Order Create Successfully!!!",
              className: "custom-class",
              style: {
                marginTop: "2vh",
              },
            });
            setLoading(false);
            history("/sales/transaction/salesOrder-detail");
          }
        })
        .catch((err) => {
          message.error({
            content: "An Error Occurred!!!!",
            className: "custom-class",
            style: {
              marginTop: "2vh",
            },
          });
          setLoading(false);
        });
    }
  };

  const handleCancel = (event) => {
    setLoading(false);
    history("/sales/transaction/salesOrder-detail");
  };

  return (
    <>
      {ad ? (
        <Row className={classes["RowP"]}>
          <Col
            lg={editMode ? 13 : 19}
            md={editMode ? 13 : 19}
            className={classes["Col"]}
          ></Col>
          {editMode ? (
            <>
              <Col md={4}>
                {loading ? (
                  <SyncLoader color={"rgba(255,163,77,0.8)"} size={10} />
                ) : (
                  <button
                    style={{
                      padding: "7px 30px 14px 24px",
                      borderRadius: "50px",
                      background: "transparent",
                      width: "100%",
                      border: "none",
                      outline: "none",
                      fontSize: "16px",
                      fontWeight: "600",
                      lineHeight: "1.5",
                      color: "#071e54",
                      WebkitAppearance: "none",

                      boxShadow:
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff", // Original box shadow
                      height: "40px",

                      // Ensure the button takes full width
                    }}
                    onClick={(event) => handleSave(event)}
                    className={classes["ProfileButtonn"]}
                    id="save"
                    onMouseOver={(event) => {
                      event.target.style.boxShadow = "0px 5px 15px 0px #264a9f"; // Apply spread color on hover with 0 spread at the top
                    }}
                    onMouseOut={(event) => {
                      event.target.style.boxShadow =
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff"; // Restore original shadow on mouse out
                    }}
                  >
                    Save
                  </button>
                )}
              </Col>
              <Col md={4}>
                {loading ? (
                  <SyncLoader color={"rgba(255,163,77,0.8)"} size={10} />
                ) : (
                  <button
                    style={{
                      padding: "7px 30px 14px 24px",
                      borderRadius: "50px",
                      background: "transparent",
                      width: "100%",
                      border: "none",
                      outline: "none",
                      fontSize: "16px",
                      fontWeight: "600",
                      lineHeight: "1.5",
                      color: "#071e54",
                      WebkitAppearance: "none",

                      boxShadow:
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff", // Original box shadow
                      height: "40px",

                      // Ensure the button takes full width
                    }}
                    onClick={(event) => handleCancel(event)}
                    onMouseOver={(event) => {
                      event.target.style.boxShadow = "0px 5px 15px 0px #264a9f"; // Apply spread color on hover with 0 spread at the top
                    }}
                    onMouseOut={(event) => {
                      event.target.style.boxShadow =
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff"; // Restore original shadow on mouse out
                    }}
                    className={classes["ProfileButtonn"]}
                  >
                    Cancel
                  </button>
                )}
              </Col>
            </>
          ) : (
            <Col md={4}>
              <button
                onClick={() => setEditMode(true)}
                className={classes["ProfileButton"]}
              >
                Edit Details
              </button>
            </Col>
          )}
          <Col lg={1} md={1}></Col>
          <Col
            lg={24}
            md={24}
            className={classes["Col"]}
            // style={{ border: "2px solid red" }}
          >
            <div className={classes["EditView"]}>
              <Col
                style={{
                  // border: "2px solid red",
                  marginTop: "5px",
                  marginBottom: "-28px",
                }}
              >
                <span style={{ fontWeight: "bold", color: "#1677ff" }}>
                  Sales Order
                </span>

                <BasicDetail
                  create={true}
                  ad={ad}
                  setData={setAllData}
                  data={allData.salesOrder[0]}
                  data1={allData.bookingSize}
                  editMode={editMode}
                  onReqCodeChange={handleReqCodeChange}
                />
              </Col>
              <Tabs
                defaultActiveKey="0"
                centered // Center the Tabs
                type="line" // Set the type to "card"
                // tabBarGutter="10"
                // id="tabs"
                // tabBarUnderlineStyle={{ borderWidth: 0 }} // Remove border from the tab bar
              >
                <TabPane
                  tab={
                    <span style={{ fontWeight: "bold", color: "000000" }}>
                      Item Details
                    </span>
                  }
                  key="0"
                  // disabled={!isItemDetailVisible}
                  style={{
                    // border: "none !important",
                    // border: "2px solid black",
                    marginTop: "-16px",
                  }}
                >
                  <div
                    style={{
                      overflowX: "scroll",
                      // padding: "10px",
                      // border: "2px solid red",
                    }}
                  >
                    {allData.salesOrder[0].type === "w" &&
                    allData.salesOrder[0].sauda_code === null ? (
                      <SizeDetail
                        ad={ad}
                        setData={setAllData}
                        data={allData.bookingSize}
                        editMode={editMode}
                        editDode={editDode}
                        setEditDode={setEditDode}
                        sizeRef={sizeRef}
                        disableItemSelection={
                          allData.salesOrder[0].type === "wr"
                        }
                      />
                    ) : (
                      <DummyTable
                        data={allData.bookingSize}
                        meta={allData.size}
                        onInputChange={handleInputChange}
                        onSelectChange={handleSelectChange}
                        onDiscountChange={handleDiscountChange}
                        onSizeChange={handleSizeChange}
                      />
                    )}
                  </div>
                </TabPane>

                {/* <TabPane
                  tab={
                    <span style={{ fontWeight: "bold", color: "000000" }}>
                      Item Tax Details
                    </span>
                  }
                  key="1"
                  style={{
                    // border: "none !important",
                    // border: "2px solid black",
                    marginTop: "-16px", // Adjust the value as needed
                    marginBottom: "16px", // Adjust the value as needed,
                  }}
                >
                  <div
                    style={{
                      overflowX: "scroll",
                      // padding: "10px",
                      // border: "2px solid red",
                    }}
                  >
                    <TaxDetail
                      create={true}
                      ad={ad}
                      setData={setAllData}
                      editMode={editMode}
                    />
                  </div>
                </TabPane> */}
              </Tabs>
            </div>
          </Col>
        </Row>
      ) : (
        <>
          <Skeleton active={true} />
          <Skeleton active={true} />
          <Skeleton active={true} />
          <Skeleton active={true} />
        </>
      )}
    </>
  );
};

export default RequisitionNew;

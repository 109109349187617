import React from "react";
import { Table, InputNumber, Select } from "antd";

const { Option } = Select;

const DummyTable = ({
  data,
  onInputChange,
  onSelectChange,
  onDiscountChange,
}) => {
  // Define columns
  const columns = [
    { title: "Order Code", dataIndex: "booking_no", key: "booking_no" },

    // { title: "Req. Date", dataIndex: "plan_date", key: "plan_date" },

    { title: "Item Name", dataIndex: "item", key: "item" },

    { title: "Hsn", dataIndex: "hsn", key: "hsn" },

    { title: "Uom", dataIndex: "uom_nm", key: "uom_nm" },

    { title: "Size", dataIndex: "size", key: "size" },

    { title: "Grade", dataIndex: "grade", key: "grade" },

    // {
    //   title: "Pcs",
    //   dataIndex: "pcs", // Assume 'pcs' is a field in the data
    //   key: "pcs",
    //   render: (text) => <span>{text}</span>, // Display pcs as non-editable text
    // },

    // {
    //   title: "Quantity",
    //   dataIndex: "total_qty",
    //   key: "total_qty",
    //   render: (text, record) => (
    //     <InputNumber
    //       value={text}
    //       onChange={(value) =>
    //         onInputChange(value, record.unique_id, "total_qty")
    //       }
    //       min={0}
    //     />
    //   ),
    // },

    {
      title: "Quantity",
      dataIndex: "total_qty",
      key: "total_qty",
      render: (text, record) => (
        <InputNumber
          value={text}
          onChange={(value) =>
            onInputChange(value, record.unique_id, "total_qty")
          }
          // min={0}
          // step={1} // Adjust the step as needed
          // parser={(value) => value.replace(/\$\s?|(,*)/g, "")} // Parse input value
          // formatter={(value) => ` ${value}`} // Format displayed value
        />
      ),
    },

    {
      title: "Rate",
      dataIndex: "rate",
      key: "rate",
      render: (text) => <span>{text}</span>, // Display rate as non-editable text
    },

    {
      title: "Dis On",
      key: "discount_on",
      render: (text, record) => (
        <span>{record.discount_on === "r" ? "Rate" : "Total Amt"}</span>
      ),
    },

    {
      title: "Discount",
      key: "discount",
      render: (text, record) => (
        <span>{record.discount}</span> // Display discount as non-editable text
      ),
    },

    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text, record) => (
        <span>{(record.total_qty * (record.rate || 0)).toFixed(2)}</span>
      ),
    },
    {
      title: "Dis Type",
      key: "dis_type",
      render: (text, record) => (
        <span>{record.dis_type === "p" ? "%" : "Amount"}</span>
      ),
    },

    {
      title: "Final Rate",
      dataIndex: "rate1",
      key: "rate1",
      render: (text) => <span>{text}</span>, // Display final rate as non-editable text
    },

    {
      title: "Net Amount",
      dataIndex: "amount2",
      key: "amount2",
      render: (text) => <span>{text}</span>, // Display net amount as non-editable text
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={data}
      rowKey={(record) => record.unique_id} // Assuming unique_code is unique for each row
    />
  );
};

export default DummyTable;

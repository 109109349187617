import { Row, Col, Card } from "antd";
import classes from "./Dashboard.module.css";
import { FaHandsHelping, FaFileInvoice, FaReceipt } from "react-icons/fa";
import { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { GiSellCard } from "react-icons/gi";
import DataContext from "../../../Context/dataContext";
const DataCards = (props) => {
  // // console.log(props.employeeData.analysis);

  // // console.log(employeeData.analysis);
  // console.log(employeeData.analysis);
  // console.log(employeeData.analysis);
  return (
    <Row>
      <Col
        lg={{ span: 8, offset: 0 }}
        md={11}
        xs={24}
        className={classes["Col"]}
      >
        <Card
          style={{
            backgroundColor: "#daebf336", // Light sky blue with 0.9 opacity
          }}
          bordered={false}
          className={classes["Card"]}
        >
          <Row>
            <Col
              md={24}
              className={classes["RataCol"]}
              style={{
                display: "flex", // Make the container a flexbox
                justifyContent: "space-between", // Add space between heading and data
                alignItems: "center", // Align items vertically in the center
                fontWeight: "900", // Make text bold
              }}
            >
              <span
                style={{ color: "#45b6fe", fontSize: "17px", fontWeight: 600 }}
              >
                Total Indent Monthly
              </span>{" "}
              {/* Heading */}
              <div
                className={classes["Rata1"]}
                style={{
                  // border: "2px solid red", // Keep the existing border style
                  padding: "2px", // Add some padding for the data box
                }}
              >
                {props.data.TotalIndentMonthlyValue !== null
                  ? props.data.TotalIndentMonthlyValue
                  : null}{" "}
                {/* Data */}
              </div>
            </Col>

            <Col
              md={24}
              className={classes["RataCol"]}
              style={{
                display: "flex", // Flexbox layout to make content inline
                justifyContent: "space-between", // Space between heading and data
                alignItems: "center", // Vertically center the content
                fontWeight: "900", // Bold text for the heading
              }}
            >
              <span
                style={{ color: "#45b6fe", fontSize: "17px", fontWeight: 600 }}
              >
                Total Indent Yearly
              </span>{" "}
              {/* Heading */}
              <div
                className={classes["Rata1"]}
                style={{
                  // color: "white", // Keep existing white color for the text
                  padding: "2px", // Add some padding for better appearance
                }}
              >
                {props.data.TotalIndentYearlyValue !== null
                  ? props.data.TotalIndentYearlyValue
                  : null}{" "}
                {/* Data */}
              </div>
            </Col>

            <Col
              md={24}
              className={classes["RataCol"]}
              style={{
                display: "flex", // Flexbox layout to make content inline
                justifyContent: "space-between", // Space between heading and data
                alignItems: "center", // Vertically center the content
                fontWeight: "900", // Bold text for the heading
              }}
            >
              <span
                style={{ color: "#45b6fe", fontSize: "17px", fontWeight: 600 }}
              >
                Pending Indent
              </span>{" "}
              {/* Heading */}
              <div
                className={classes["Rata1"]}
                style={{
                  // color: "white", // Keep existing white color for the text
                  padding: "2px", // Add some padding for better appearance
                }}
              >
                {props.data.TotalPendingIndentValue !== null
                  ? props.data.TotalPendingIndentValue
                  : null}{" "}
                {/* Data */}
              </div>
            </Col>
          </Row>
        </Card>
      </Col>
      <Col
        lg={{ span: 8, offset: 0 }}
        md={11}
        xs={24}
        className={classes["Col"]}
      >
        <Card
          style={{
            background: "#daebf336",
          }}
          bordered={false}
          className={classes["Card"]}
        >
          <Row>
            <Col
              md={24}
              className={classes["RataCol"]}
              style={{
                display: "flex", // Make the container a flexbox
                justifyContent: "space-between", // Add space between heading and data
                alignItems: "center", // Align items vertically in the center
                fontWeight: "900", // Make text bold
              }}
            >
              <span
                style={{ color: "#45b6fe", fontSize: "17px", fontWeight: 600 }}
              >
                Total PO Monthly
              </span>{" "}
              {/* Heading */}
              <div
                className={classes["Rata1"]}
                style={{
                  // border: "2px solid red", // Keep the existing border style
                  padding: "2px", // Add some padding for the data box
                }}
              >
                {props.data.TotalMonthlyPoValue !== null
                  ? props.data.TotalMonthlyPoValue
                  : null}{" "}
                {/* Data */}
              </div>
            </Col>

            <Col
              md={24}
              className={classes["RataCol"]}
              style={{
                display: "flex", // Flexbox layout to make content inline
                justifyContent: "space-between", // Space between heading and data
                alignItems: "center", // Vertically center the content
                fontWeight: "900", // Bold text for the heading
              }}
            >
              <span
                style={{ color: "#45b6fe", fontSize: "17px", fontWeight: 600 }}
              >
                Total PO Yearly
              </span>{" "}
              {/* Heading */}
              <div
                className={classes["Rata1"]}
                style={{
                  // color: "white", // Keep existing white color for the text
                  padding: "2px", // Add some padding for better appearance
                }}
              >
                {props.data.TotalYearlyPoValue !== null
                  ? props.data.TotalYearlyPoValue
                  : null}{" "}
                {/* Data */}
              </div>
            </Col>

            <Col
              md={24}
              className={classes["RataCol"]}
              style={{
                display: "flex", // Flexbox layout to make content inline
                justifyContent: "space-between", // Space between heading and data
                alignItems: "center", // Vertically center the content
                fontWeight: "900", // Bold text for the heading
              }}
            >
              <span
                style={{ color: "#45b6fe", fontSize: "17px", fontWeight: 600 }}
              >
                Pending PO For Gate Pass
              </span>{" "}
              {/* Heading */}
              <div
                className={classes["Rata1"]}
                style={{
                  // color: "white", // Keep existing white color for the text
                  padding: "2px", // Add some padding for better appearance
                }}
              >
                {props.data.TotalPendingPoValue !== null
                  ? props.data.TotalPendingPoValue
                  : null}{" "}
                {/* Data */}
              </div>
            </Col>

            <Col
              md={24}
              className={classes["RataCol"]}
              style={{
                display: "flex", // Flexbox layout to make content inline
                justifyContent: "space-between", // Space between heading and data
                alignItems: "center", // Vertically center the content
                fontWeight: "900", // Bold text for the heading
              }}
            >
              <span
                style={{ color: "#45b6fe", fontSize: "17px", fontWeight: 600 }}
              >
                Pending PO For Mrir
              </span>{" "}
              {/* Heading */}
              <div
                className={classes["Rata1"]}
                style={{
                  // color: "white", // Keep existing white color for the text
                  padding: "2px", // Add some padding for better appearance
                }}
              >
                {props.data.TotalPendingPoformrirValue !== null
                  ? props.data.TotalPendingPoformrirValue
                  : null}{" "}
                {/* Data */}
              </div>
            </Col>
          </Row>
        </Card>
      </Col>

      <Col
        lg={{ span: 8, offset: 0 }}
        md={11}
        xs={24}
        className={classes["Col"]}
      >
        <Card
          style={{
            backgroundColor: "#daebf336", // Very light gray with 0.9 opacity
          }}
          bordered={false}
          className={classes["Card"]}
        >
          <Row>
            <Col
              md={24}
              className={classes["RataCol"]}
              style={{
                display: "flex", // Make the container a flexbox
                justifyContent: "space-between", // Add space between heading and data
                alignItems: "center", // Align items vertically in the center
                fontWeight: "900", // Make text bold
              }}
            >
              <span
                style={{ color: "#45b6fe", fontSize: "17px", fontWeight: 600 }}
              >
                Total Gate Pass Monthly
              </span>{" "}
              {/* Heading */}
              <div
                className={classes["Rata1"]}
                style={{
                  // border: "2px solid red", // Keep the existing border style
                  padding: "2px", // Add some padding for the data box
                }}
              >
                {props.data.TotalMonthlyGateValue !== null
                  ? props.data.TotalMonthlyGateValue
                  : null}{" "}
                {/* Data */}
              </div>
            </Col>

            <Col
              md={24}
              className={classes["RataCol"]}
              style={{
                display: "flex", // Make the container a flexbox
                justifyContent: "space-between", // Add space between heading and data
                alignItems: "center", // Align items vertically in the center
                fontWeight: "900", // Make text bold
              }}
            >
              <span
                style={{ color: "#45b6fe", fontSize: "17px", fontWeight: 600 }}
              >
                Total Gate Pass Yearly
              </span>{" "}
              {/* Heading */}
              <div
                className={classes["Rata1"]}
                style={{
                  // border: "2px solid red", // Keep the existing border style
                  padding: "2px", // Add some padding for the data box
                }}
              >
                {props.data.TotalYearlyGatePassValue !== null
                  ? props.data.TotalYearlyGatePassValue
                  : null}{" "}
                {/* Data */}
              </div>
            </Col>

            <Col
              md={24}
              className={classes["RataCol"]}
              style={{
                display: "flex", // Flexbox layout to make content inline
                justifyContent: "space-between", // Space between heading and data
                alignItems: "center", // Vertically center the content
                fontWeight: "900", // Bold text for the heading
              }}
            >
              <span
                style={{ color: "#45b6fe", fontSize: "17px", fontWeight: 600 }}
              >
                Pending Gate Pass
              </span>{" "}
              {/* Heading */}
              <div
                className={classes["Rata1"]}
                style={{
                  // color: "white", // Keep existing white color for the text
                  padding: "2px", // Add some padding for better appearance
                }}
              >
                {props.data.TotalPendingGatepassforgatepassValue !== null
                  ? props.data.TotalPendingGatepassforgatepassValue
                  : null}{" "}
                {/* Data */}
              </div>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default DataCards;

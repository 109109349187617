import { Row, Col, Card, Spin } from "antd"; // Import Spin for loading indicator
import classes from "./Dashboard.module.css";
import DataCards from "./DataCards";
import { useEffect, useContext, useState } from "react";
import axios from "axios";
import DataContext from "../../../Context/dataContext";
import Charts from "./Charts";

const Dashboard = () => {
  const [analysis, setAnalysis] = useState(null);
  const [loading, setLoading] = useState(true); // State for loading
  const employeeData = useContext(DataContext);
  // console.log(employeeData);

  useEffect(() => {
    axios
      .get(employeeData.URL + "/api/v1/dashboard/dash-board", {
        withCredentials: true,
      })
      .then((response) => {
        console.log(response.data.data);
        setAnalysis(response.data.data);
        setLoading(false); // Set loading to false when data is fetched
      })
      .catch((err) => {
        console.error(err);
        setLoading(false); // In case of error, also stop loading
      });
  }, []);

  if (loading) {
    return (
      <Row justify="center" align="middle" style={{ height: "100vh" }}>
        <Spin size="large" /> {/* Loading spinner */}
      </Row>
    );
  }

  return (
    <>
      <Row gutter={16} className={classes["SiteLayoutBackground"]}>
        <Col xl={24} lg={24} md={24}>
          <DataCards data={analysis} />
        </Col>
        <p></p>
      </Row>
      <Row>
        <Col
          lg={{ span: 6, offset: 0 }}
          md={24}
          xs={24}
          className={classes["ColC"]}
        >
          <Card className={classes["Card"]}>
            <Charts
              chartType="pie"
              data={analysis.formattedData}
              type={"item"}
            />
          </Card>
        </Col>
        <Col
          lg={{ span: 6, offset: 0 }}
          md={24}
          xs={24}
          className={classes["ColC"]}
        >
          <Card className={classes["Card"]}>
            <Charts
              chartType="pie"
              data={analysis.formattedDataSize}
              type={"size"}
            />
          </Card>
        </Col>
        <Col
          lg={{ span: 6, offset: 0 }}
          md={24}
          xs={24}
          className={classes["ColC"]}
        >
          <Card className={classes["Card"]}>
            <Charts
              chartType="pie"
              data={analysis.formattedDataGrade}
              type={"grade"}
            />
          </Card>
        </Col>
        <Col
          lg={{ span: 6, offset: 0 }}
          md={24}
          xs={24}
          className={classes["ColC"]}
        >
          <Card className={classes["Card"]}>
            <Charts
              chartType="pie"
              data={analysis.formattedDataCustomer}
              type={"customer"}
            />
          </Card>
        </Col>
      </Row>
      <p></p>
    </>
  );
};

export default Dashboard;
